.router_link {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.829);
}

.routing_button:hover {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.829);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(140, 189, 32, 1) 0%,
    rgba(74, 177, 14, 0.97) 100%
  ) !important;
}

.routing_button svg {
  color: white !important;
}

.background {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(140, 189, 32, 1) 0%,
    rgba(74, 177, 14, 0.97) 100%
  ) !important;
}

#background {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(140, 189, 32, 1) 0%,
    rgba(74, 177, 14, 0.97) 100%
  ) !important;
}

.font500 {
  font-weight: 600 !important;
}
.Typo5Bold {
  font-weight: 700;
  font-family: system-ui;
}

.div-title {
  font-size: 19px !important;
  font-weight: 600 !important;
  font-family: system-ui;
  color: rgba(31, 31, 31, 0.952);
}
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}

.d-flex {
  display: flex !important;
  align-items: center;
}

.jc-sb {
  justify-content: space-between !important;
}
.subtitle2lg {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: rgb(54, 54, 54) !important;
}

.text-14 {
  font-size: 14px !important;
  margin-right: 5px;
}

.margin {
  margin-bottom: -5px !important;
}

/* Nav Css */
.navbar {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 15px;
}
.avatar {
  padding-left: 20px;
  padding-right: 20px;
}

/* ------  */
.div-shadow {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important;
  height: 100%;
  padding: 10px;
  border-radius: 5px;
}
