.editProfileButton {
  border: none !important;
  border-radius: 25px;
  color: rgb(85, 130, 145);
  font-size: 10px;
}
.editProfileButton svg {
  font-size: 20px;
}
.justify-items-center {
  justify-items: center;
}
.border-box {
  border-right: 1px solid rgb(156, 156, 156);
  color: rgb(70, 70, 70);
}

.border-box h6 {
  font-weight: 400 !important;
}
.h-100 {
  min-height: 30vh !important;
}

.updateImage {
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 1.5 !important;
  color: rgba(0, 0, 0, 0.89) !important;
  width: 100%;
  border-radius: 0px !important;
}
.buttonOpenUpdate {
  width: 30px;
  border: none;
  margin-left: 10px;
  background: none;
  border-radius: 25px;
  display: flex;
  justify-content: center;
}

.buttonOpenUpdate:hover {
  background: rgba(125, 168, 114, 0.089);
}
@media only screen and (max-width: 600px) {
  .border-box h6 {
    font-size: 12px !important;
  }
  .border-box h5 {
    font-size: 20px !important;
  }
}
