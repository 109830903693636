.OnPassword {
  color: #4d506c;
}
.d-flex-c-d {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5865f2;
  color: white !important;
  text-decoration: none;
  margin-top: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 5px;
}

.d-flex-c-d:hover {
  background: #505cda;
}

.discord_i {
  width: 30px !important;
  margin-right: 10px;
}

.box-shadow-c {
  box-shadow: rgba(255, 255, 255, 0.05) 0px 6px 24px 0px,
    rgba(254, 254, 254, 0.08) 0px 0px 0px 1px;
  /* border-radius: 10px; */
}

.logo_c {
  color: white;
}

.account-pages {
  height: 100vh;
  display: flex;
  align-items: center;
}

.position_ {
  position: relative;
}

.receive_ {
  position: absolute;
  /* left: 38%; */
  bottom: 25px;
}
.decoration {
  text-decoration: underline;
}
